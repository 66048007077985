import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isEmpty } from 'lodash';
import { Store } from '@ngrx/store';
import {
  DatabaseDestinationComponentData,
  DatabaseSourceComponentData,
  MongoDestinationComponentData,
  MongoSourceComponentData,
  SnowflakeDestinationComponentData,
} from '../../package.models';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';
import { addVariable, removeVariable } from '../../store/variables.actions';

export type DynamicConnectionComponentData =
  | DatabaseSourceComponentData
  | DatabaseDestinationComponentData
  | MongoSourceComponentData
  | MongoDestinationComponentData
  | SnowflakeDestinationComponentData;

@Component({
  selector: 'dynamic-connection',
  template: `
    <div class="dynamic-connection">
      <div class="row">
        <xp-input-checkbox
          class="dynamic-connection-checkbox"
          [(ngModel)]="isDynamicConnection"
          name="Set variable for connection"
          labelText="Set variable for connection"
          [disabled]="!component.connection || !component.connection.id"
          (ngModelChange)="updateDynamicConnection($event)"
        ></xp-input-checkbox>
      </div>

      <div>
        <variables-editor-row
          [item]="item"
          [disableKey]="true"
          [disableValue]="!isDynamicConnection"
          [hideRemove]="true"
          [disableSort]="true"
          [index]="0"
          [type]=""
          key="key"
          value="value"
          (itemChange)="onValueChange($event)"
          hint="Add the connection ID of the connection you would like to use. Connection must be of the same type as input connection selected on step 1."
        ></variables-editor-row>
      </div>
    </div>
  `,
  providers: [],
})
export class DynamicConnectionComponent implements OnInit, OnChanges {
  @Input() component: DynamicConnectionComponentData;
  item: any = {};
  isDynamicConnection = false;
  previousKey = '';

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.isDynamicConnection = !!this.component.dynamic_connection;

    if (!this.item.value && this.dynamicConnectionValue) {
      this.item = {
        value: `'${this.dynamicConnectionValue}'`,
        key: `${this.component.name}_connection`,
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.item.value && this.dynamicConnectionValue) {
      this.item = {
        value: `'${this.dynamicConnectionValue}'`,
        key: `${this.component.name}_connection`,
      };
    }

    if (changes.component?.currentValue) {
      const currentComponent = changes.component?.currentValue;
      const previousComponent = changes.component?.previousValue;

      if (
        previousComponent?.connection?.id &&
        previousComponent?.connection?.id !== currentComponent?.connection?.id &&
        this.item.value
      ) {
        this.item.value = `'${this.component.connection.unique_id}'`;
        this.onValueChange(this.item);
      }

      if (previousComponent?.name && previousComponent?.name !== currentComponent?.name) {
        this.previousKey = this.item.key;
        this.item.key = `${this.component.name}_connection`;
        this.onValueChange(this.item);
      }
    }
    if (!this.item.value && this.dynamicConnectionValue) {
      this.item = {
        value: `'${this.dynamicConnectionValue}'`,
        key: `${this.component.name}_connection`,
      };
    }
  }

  updateDynamicConnection(value: boolean) {
    if (value && !this.item.value) {
      this.setItem();
    } else if (!value) {
      this.removeDynamicConnection();
    }

    if (!isEmpty(this.item) && value) {
      this.onValueChange(this.item);
    }
  }

  removeDynamicConnection() {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { dynamic_connection: '' },
      }),
    );
    this.store.dispatch(updateComponent({ component: { dynamic_connection: '' } }));
    this.store.dispatch(removeVariable({ variableName: this.item.key }));
  }

  setItem() {
    this.item = {
      value: `'${this.component.connection.unique_id}'`,
      key: `${this.component.name}_connection`,
    };
    this.onValueChange(this.item);
  }

  onValueChange(item) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { dynamic_connection: item.value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { dynamic_connection: item.value } }));

    this.updateVariables();
  }

  updateVariables() {
    this.store.dispatch(addVariable({ variables: { [this.item.key]: this.item.value } }));

    if (this.previousKey) {
      this.store.dispatch(removeVariable({ variableName: this.previousKey }));
    }
  }

  get dynamicConnectionValue(): string {
    return (this.component.dynamic_connection || '').replace(/'/g, '') || this.component.connection?.unique_id;
  }
}
